import signal from 'signal-js'

export default () => {
    
    let NAV = {}

    let body = $('body')
    let burger = $('#burger')
    
    NAV.scrollTo = (top, t, d) => {
        setTimeout(() => {
            $('html, body').animate({scrollTop: top}, t)
        }, d)
    }

    NAV.scrollToEl = (selector, t, d) => {
        setTimeout(() => {
            $('html, body').animate({scrollTop: $(selector).offset().top}, t)
        }, d)
    }

    NAV.toggleMenu = () => {
        if(body.hasClass('show-menu')) {
            body.removeClass('show-menu')
        } else {
            body.addClass('show-menu')
        }
    }
    
    burger.click(NAV.toggleMenu)

    signal.on('scroll-to', NAV.scrollTo)
    signal.on('scroll-el', NAV.scrollToEl)

    // other rules

    $('.header-logo').on('click', (e) => {
        
        if( $(window).scrollTop() > 100 && !$('body').hasClass('show-menu') ){
            NAV.scrollTo(0, 1000)
            e.preventDefault()
        }
        
    })
    
    $('.header-menu-links a[href^="#scroll="]').on('click', (e) => {
        e.preventDefault()
        NAV.scrollToEl($(e.currentTarget).attr('href').substring(8), 1000)
        body.removeClass('show-menu')
    })
    
    return NAV
}