export default () => {

    let list = $('.projetos-index-list-wrapper').isotope({
        // options
        itemSelector: '.projeto',
        transitionDuration: '0.8s',
        masonry: {
        // use element for option
            columnWidth: '.projeto'
        }
    });

    let filter_links = $('.projetos-index-filter a')

    filter_links.on('click', (e) => {
        e.preventDefault()
        let link = $(e.currentTarget)
        isofilter(link.attr('rel'))
    })

    const isofilter = (slug) => {
        filter_links.removeClass('active')
        filter_links.filter(`[rel="${slug}"]`).addClass('active')
        list.isotope({ filter: `${slug}` })
    }
    
    return {
        list,
        filter_links
    }
}