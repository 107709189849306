export default () => {

    let mosaicos = document.querySelectorAll('.galeria-mosaico')
    let macys = []

    mosaicos.forEach((el,i) => {

        let id = `mosaico-${i}`

        $(el).attr('id', id)

        var macy = Macy({
            container: `#${id}`,
            columns: 3,
            margin: 24,
            breakAt: {
                // 1200: 4,
                // 940: 3, 
                640: 2
            }
        })

        macys.push(macy)

    })

    
    return {
        mosaicos,
        macys
    }

}