import ready from './ready'
import nav from './nav'
import magic from './magic'
import swipers from './swipers'
import accordions from './accordion'
import projetos from './projetos'
import galeria from './galeria'

ready()
window.nav = nav()
window.magic = magic()
window.swipers = swipers()
window.accordions = accordions()
window.projetos = projetos()
window.galeria = galeria()


