import ScrollMagic from 'scrollmagic'
import signal from 'signal-js'

let MAGIC

MAGIC = () => {

    let magicFull = registerMagic('full')
    let magicTop = registerMagic('top')

    document.addEventListener( 'mousemove', updateParallax)
    document.addEventListener( 'scroll', updateParallax)

    // scrollbar = Scrollbar.init(document.querySelector('#main-scrollable'))

    // scrollbar.addListener(() => {
    //     scenes.forEach((el,i) => {
    //         el.refresh()
    //     })
    // })

    return {
        magicFull,
        magicTop
    }

}

const registerMagic = (mode) => {

    let controller = new ScrollMagic.Controller({
        //refreshInterval: 0
    })

    let scenes = []
    let appear = document.querySelectorAll('section')

    appear.forEach((el,i) => {

        el.id = 'section-' + i

        const id = `#${el.id}`
        
        let timeout = null

        const param = {
            full: {
                triggerElement: id,
                triggerHook: 1, //0.9,
                duration: () => el.offsetHeight + window.innerHeight, //"80%", 
                offset: 0 //el.offsetHeight * 0.5
            },
            top: {
                triggerElement: id,
                triggerHook: 0,
                duration: () => el.offsetHeight,
                offset: '-50px'
            }
        }

        var scene = new ScrollMagic.Scene(param[mode])
        .setClassToggle(id, "visible")
        .on("enter", (event) => addDirectionClass(id, mode, 'enter', event, timeout))
        .on("leave", (event) => addDirectionClass(id, mode, 'leave', event, timeout))
        .on("progress", (event) => onProgress(id, mode, event))
        .addTo(controller)

        scenes.push(scene)
    })

    return {
        controller,
        scenes
    }

}

const addDirectionClass = (id, mode, name, event, timeout) => {

    const states = ['enter-forward', 'enter-reverse', 'leave-forward', 'leave-reverse']
    const classState = `${name}-${event.scrollDirection.toLowerCase()}`

    states.map((val,i)=>{
        if(val != name){
            $(id).removeClass(val)
        }
    })

    $(id).addClass(classState)
    
    let bodyclass = $(id).attr('data-magic-bodyclass')

    if(bodyclass){
        if(name == 'enter'){
            $('body').addClass(mode + '-' + bodyclass)
        } else {
            $('body').removeClass(mode + '-' + bodyclass)
        }
    }

    if(timeout) clearTimeout(timeout)
    timeout = setTimeout(() => $(id).removeClass(classState), 1000)

    signal.emit(`magic-${name}`, id, event.scrollDirection)
}

const onProgress = (id, mode, event) => {

    if(mode != 'full') return false

    //console.log(id, 'progress', event.progress, event.state, event.scrollDirection)
    const section = document.querySelector(id)
    const w = section.offsetWidth
    const h = section.offsetHeight

    const factor = mapRange(w, 240, 1920, 50, 200)

    document.querySelectorAll(`${id} .parallax-svg`).forEach((el,i) => {

        const parallax = Number(el.getAttribute('data-lax'))
        const x = Number(el.getAttribute('data-lax-x')) * w
        const y = Number(el.getAttribute('data-lax-y')) * h
        el.style.transform = `translate3d(${x}px,${y + event.progress * parallax * factor}px,0)`
        
    })
    
}

const updateParallax = (e) => {

    const w = window.innerWidth;
    const h = window.innerHeight;
    const offsetX = 0.5 - e.screenX / w;
    const offsetY = 0.5 - e.screenY / h;

    if(w < 1024) return

    let images = document.querySelectorAll('.parallax-svg img')

    images.forEach((el,i) => {
        const factor = 20 * (10-Number(el.parentNode.getAttribute('data-lax')))
        //el.style.transform = `translate3d(${offsetX * factor}px,${offsetY * factor}px,0)`
    })

}

const mapRange = (value, a, b, c, d) => {
    // first map value from (a..b) to (0..1)
    value = (value - a) / (b - a);
    // then map it from (0..1) to (c..d) and return it
    return c + value * (d - c);
}


export default MAGIC
