import Swiper from 'swiper'

export default () => {
    
    let sliders = []

    document.querySelectorAll('section.slider-projects').forEach((el,i) => {

        var mySwiper = new Swiper (el.querySelector('.swiper-container'), {
            direction: 'horizontal',
            loop: true,
              //spaceBetween: 100,
              slidesPerView: 'auto',
              centeredSlides: true,
            navigation: {
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
            },
        })

        sliders.push(mySwiper)

    })
    
    return {
        sliders
    }
}