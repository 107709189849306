export default () => {

    let accordions = $('a.accordion-trigger')
    
    accordions.on('click', (e) => {
        e.preventDefault()
        let a = $(e.currentTarget)
        $(a.attr('href')).toggleClass('closed')
        a.find('.icon-more').toggleClass('closed')
    })
    
    return accordions
}